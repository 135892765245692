export const VALUES = [
  {
    url: '/assets/banner-iffs.png',
    alt: 'Invaders from far Space',
    title: 'Invaders from far Space',
    text: 'Upgrade your fighter, pick the green gifts and shoot your guns to destroy aliens'
  },
  {
    url: '/assets/banner-simon.png',
    alt: 'Repeat the color',
    title: 'Repeat the color',
    text: 'A simple and addictive Simon (tm) game that will help to improve your memory'
  },
  {
    url: '/assets/banner-nightwatch.png',
    alt: 'NightWatch - Clock',
    title: 'NightWatch - Clock',
    text: 'NightWatch - Clock, install and use your device like a clock for your bed table'
  },
  {
    url: '/assets/banner-alarm.png',
    alt: 'Personal security home alarm',
    title: 'Personal security home alarm',
    text: 'You can arm an alarm wherever you want, everytime you need an intrusion system'
  }
];
