import { Component } from '@angular/core';
import { VALUES } from './carousel.constants';

@Component(
  {
    selector: 'rfm-carousel',
    templateUrl: './carousel.component.html'
  })

export class CarouselComponent {
  images = VALUES;
}
